import { request } from "@/request/http";

export default {
  // getActivityList: (data) => {
  //   return request("POST", `/index.php/group/getActivityList`, data);
  // },

  getVirtualDataList: (data) => {
    return request("GET", `/index.php/course/getVirtualDataList`, data);
  },

  unLockTotalExp: (data) => {
    return request("GET", `/index.php/course/unLockTotalExp`, data);
  },

  downloadCertPdf: (data) => {
    return request("POST", `/index.php/group/downloadCertPdf`, data);
  },

  updateSortNo: (data) => {
    return request("GET", `/index.php/group/updateSortNo`, data);
  },

  CourseExpCensus: (data) => {
    return request("GET", `/index.php/group/CourseExpCensus`, data);
  },

  getCertReckonList: (data) => {
    return request("GET", `/index.php/group/getCertReckonList`, data);
  },

  getGameTypeList: (data) => {
    return request("GET", `/index.php/group/getGameTypeList`, data);
  },

  getGameList: (data) => {
    return request("GET", `/index.php/group/getGameList`, data);
  },

  showRelease: (data) => {
    return request("GET", `/index.php/group/showRelease`, data);
  },

  createExpRecord: (data) => {
    return request("POST", `/index.php/group/createExpRecord`, data);
  },

  delStudentBrush: (data) => {
    return request("POST", `/index.php/group/delStudentBrush`, data);
  },

  updateStudentGroup: (data) => {
    return request("GET", `/index.php/group/updateStudentGroup`, data);
  },

  deleteStudent: (data) => {
    return request("GET", `/index.php/group/deleteStudent`, data);
  },

  markRelease: (data) => {
    return request("GET", `/index.php/group/markRelease`, data);
  },

  addStudentToStuGrpDetail: (data) => {
    return request("POST", `/index.php/group/addStudentToStuGrpDetail`, data);
  },

  purchaseExpCourse: (data) => {
    return request("POST", `/index.php/group/purchaseExpCourse`, data);
  },

  toGetTeacherBuyAllGrpStuCount: (data) => {
    return request("POST", `/index.php/group/toGetTeacherBuyAllGrpStuCount`, data);
  },

  getGroupStudentInfo: (data) => {
    return request("GET", `/index.php/group/getGroupStudentInfo`, data);
  },

  // updateSortNo: (data) => {
  //   return request("GET", `/index.php/group/updateSortNo`, data);
  // },

  getExpList: (data) => {
    return request("GET", `/index.php/group/getExpList`, data);
  },

  updateGroup: (data) => {
    return request("GET", `/index.php/group/updateGroup`, data);
  },

  updateGroupExec: (data) => {
    return request("GET", `/index.php/group/updateGroupExec`, data);
  },

  getGroupList: (data) => {
    return request("GET", `/index.php/group/getGroupList`, data);
  },

  getGameDataList: (data) => {
    return request("POST", `/index.php/group/getGameDataList`, data);
  },

  showUNreleased: (data) => {
    return request("POST", `/index.php/group/showUNreleased`, data);
  },

  sendWxMessage: (data) => {
    return request("POST", `/index.php/group/sendWxMessage`, data);
  },

  createGroupCode: (data) => {
    return request("POST", `/index.php/group/createGroupCode`, data);
  },

  getExpCidStat: (data) => {
    return request("POST", `/index.php/group/getExpCidStat`, data);
  },

  getStatData: (data) => {
    return request("POST", `/index.php/group/getStatData`,data)
  }
  
};