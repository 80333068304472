// 引入基本模板
import * as echarts from 'echarts';
// 引入饼图组件
require('echarts/lib/chart/pie');
// 引入柱状图组件
require("echarts/lib/chart/bar");
// 引入提示框和title组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
require("echarts/lib/component/legend");

import { GridComponent,  
  TitleComponent,
  TooltipComponent,
  LegendComponent 
} from "echarts/components";
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  CanvasRenderer
]);

// 引入自定义函数
import { sleep, startLoading, endLoading } from "@/utils/function";
import {
  domainUrl,
  studentGroupPageSize,
  studentScorePageSize,
  studentGamePageSize,
  studentCertPageSize,
} from "@/utils/const";

//百度地图
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import DownLoad from "../../../components/DownLoad.vue";
import API from '@/api/teacher/group/groupList';

import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";

export default {
  name: "other",
  components: {
    BaiduMap,
    DownLoad
  },
  data() {
    return {
      // 班级码图片链接
      groupCodeImgSrc:'',
      // 当前时间
      timenow:'',
      // 群组码对话框
      groupDialogVisible:false,
      // 发送通知对话框
      sendNoticeDialogVisible:false,
      // 通知内容
      noticeValue:'',
      // 班级是否可拖拽排序
      sortAbled: false,
      // 安全准入搜索关键词
      certSearch: '',
      // 班级成绩搜索关键词
      studentGroupSearch: '',
      classCourseList: [],
      isRecover: true,
      isShow: true,
      // 安全教育小游戏数据列表弹窗标题
      gamedataDialogTitle: '',
      // 安全教育小游戏数据列表用户id
      user_id: '',
      // 安全教育小游戏数据列表
      gamedataList: [],
      // 安全教育小游戏数据列表页码
      gamedatalistPage: 1,
      // 安全教育小游戏数据列表数据总数
      gamedatalistTotal: 0,
      // 安全教育小游戏数据列表弹窗显示与隐藏
      gamedataDialogVisible: false,
      multipleSelection: [],
      // =======================================================
      classTypeList: [
        {
          id: 0,
          name: '在修'
        },
        {
          id: 1,
          name: '重修'
        }
      ],
      groupType: '',
      // 左侧项目列表 数据
      // 已删除班级显示标识
      deleteGroupShowSign: false,
      // 标签页默认选中项
      activeName: "first",
      // 项目列表
      groupList: [
        {
          "id":10161,
          "name":"大连医科大学",
          "type":0
        },
        {
          "id":99990,
          "name":"大连医科大学附属第一医院",
          "type":0
        }
      ],
      // 课程列表
      classList: [],
      // (筛选)课程id
      class_id: 0,
      // 删除项目列表
      deleteGroupList: [],
      // 已删除的项目数量
      deleteNum: 0,
      // 当前正在显示信息的项目id
      currentGroup_id: 0,
      // 右侧tab页内容显示
      rightContentShow: true,
      // 左侧班级弹窗显示标识
      leftDialogVisible: false,
      // 左侧弹窗标题
      leftDialogTitle: "",
      // 左侧弹窗标识
      leftDialogSign: "",
      // 班级id
      group_id: 10161,
      // 左侧班级id
      left_group_id: "",
      // 班级名称
      groupName: "",
      // 添加/修改班级课程id
      course_id: "",
      // 证书课程id
      cert_course_id: "",

      // ===============================================================
      // 左侧列表数据
      // 学生列表数据
      studentListTableData: [],
      // 学生列表总数
      studentListTotal: 0,
      // 学生列表页码
      studentListPage: 1,
      // 学生列表每页显示数据量
      studentListPageSize: studentGroupPageSize,
      // checkbox 全选标识
      checkAll: false,
      // 多选框选择数据
      checkboxArr: [],
      // 学生列表搜索值
      studentListSearch: "",
      // 弹窗显示标识
      rightStudentDialogVisible: false,
      // 弹窗操作标识
      rightStudentDialogSign: "",
      // 弹窗显示标题
      rightStudentDialogTitle: "",
      // 班级导入学生上传文件路径
      groupFilePath: "",
      // 上传文件列表
      fileList: [],
      // 添加学号
      studentNumber: "",
      // 解锁需要花费的分数
      lockPoint: "",
      // 上传url
      uploadUrl:
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/group/uploadExportGroupFile",

      // ==============================================================================
      // 右侧学生成绩数据
      // list数据
      scoreTableData: [],
      // 项目标题数据
      scoreTableTitleList: [],
      // 学生列表总数
      scoreTotal: 0,
      // 学生列表页码
      scorePage: 1,
      // 学生列表每页显示数据量
      scorePageSize: studentScorePageSize,

      // ==============================================================================
      // 右侧学生游戏数据
      // 列表数据
      gameTableData: [],
      // 学生列表总数
      gameTotal: 0,
      // 学生列表页码
      gamePage: 1,
      // 学生列表每页显示数据量
      gamePageSize: studentGamePageSize,

      // ==============================================================================
      // 右侧证书列表数据
      // 列表数据
      certTableData: [],
      // 学生列表总数
      certTotal: 0,
      // 学生列表页码
      certPage: 1,
      // 学生列表每页显示数据量
      certPageSize: studentCertPageSize,
      // 证书信息
      certInfo: {},
      // 小游戏列表
      gamelist: [],
      // 小游戏id
      game_id: "",
      // 游戏类型id
      game_type_id: "",
      // 游戏类型列表
      gameTypelist: [],
      // 小游戏活动id
      // game_active_id:"",
      // 游戏活动列表
      // gameActivelist:[],

      // ==============================================================================
      // 右侧虚拟列表数据
      // 虚拟列表数据
      expList: [],
      // 筛选日期数组['开始时间','结束时间']
      virtualDate: [],
      // 虚拟实验id
      virtualExpId: "",
      // 虚拟数据展示
      virtualDataShow: false,
      // 虚拟统计数据
      virtualData: "",
      // 深拷贝的班级列表数据
      groupList2: [],
      // 虚拟项目列表
      expprojectList: [],
      // 账户总积分
      balance: '',
      // 解锁人数
      lockNum: '',
      // 花费总积分
      totalMoneySpent: '',
      // 控制全部解锁弹窗显示与隐藏
      allunlockDialogVisible: false,
      // 控制统计弹窗显示与隐藏
      stasticDialogVisible: false,
      // 虚拟搜索关键词
      virtualSearch: '',
      // 已获取证书数量
      certNum: 0,
      // 标识
      census:0,
      // 查看类型
      lookType:'1',
      // 导出成绩弹窗
      scoreDialogVisible:false,
      // 原始成绩标识
      rawScoreSign:false,
      // 计算成绩标识
      calcScoreSign:false,
      // 开放时间标识
      openTimeSign:false,

      // ========================================================
      // add by camellia 20230328 学情分析虚拟实验关卡数据统计
      // 数据统计弹窗分页
      statPage:1,
      // 数据统计弹窗标识
      echartsDialogVisible:false,
      // 数据统计弹窗标题
      echartsDialogTitle:'',
      // 数据统计弹窗宽度
      echartsDialogWidth:'70%',
      // 数据统计弹窗分页总数
      echartsDialogTotal:0,
      // 数据统计弹窗统计关卡
      echartsDialogIndex:0,
      // 班级名称
      student_group_name:'',  
      // 班级学生总人数
      studentNum:0,
      // 班级学生注册人数
      studentRegNum:0,

      // ==========================================================
      // add by wyf 2023-05-31 学生总分分布统计
      // 班级学生成绩
      studentScore:{},
      // 学生成绩统计图的显示与隐藏
      studentScoreDialogVisible: false,
      // 存放学生成绩图表的echarts实例
      studentScoreEcharts: null,

    };
  },
  /**
   * @name: 页面创建成功之后
   * @author: camellia
   * @date: 2021-03-04
   */
  created() 
  {
    const self = this;
    // 接受路由参数
    self.input = this.$router.currentRoute.query.search;
    self.page = Number(this.$router.currentRoute.query.page);
    self.group_id = this.$router.currentRoute.query.group_id;
    if(!self.group_id)
    {
      self.group_id = 10161;
    }
    self.virtualExpId = this.$router.currentRoute.query.exp_id;
    self.activeName = this.$router.currentRoute.query.activeName ?? '';
    self.census = this.$router.currentRoute.query.census ?? 0;
    // 初始化 学情分析 时间
    let date = new Date();
    let startDate = date.getFullYear() + "-" +(date.getMonth()+1)+ "-1";
    let endDate = date.getFullYear() + "-" +(date.getMonth()+2)+ "-1";
    self.virtualDate = [startDate,endDate];
    // 获取数据
    self.getExpList()
  },

  watch: {
    //  如果需要第一次就执行监听 则需要设置：immediate: true
    lookType: {
      handler(newVal,oldVal) 
      { 
        this.lookType = newVal;
        this.getReleaseScore();
      },
      immediate: true
    }, 
  },

  mounted() 
  {
    const self = this;
    if (self.groupList.length > 0 ) 
    {
      sleep(50).then(() => {
        self.updateCssStyle(self.groupList[0].id);
      });
    }
  },
  methods: 
  {
    getcurrentActive(id, courseid, name) 
    {
      let self = this;
      self.isShow = true;
      self.virtualExpId = '';
      self.virtualDataShow = false;
      self.isRecover = false;
      self.rightContentShow = true;
      self.group_id = id;
      self.group_name = name;
      self.cert_course_id = courseid;
      sleep(50).then(() => {
        self.updateCssStyle(self.group_id);
      });
      self.getExpList();
    },

    allUnlock() {
      let self = this
      let data = {
        group_id: self.group_id,
        totalMoneySpent: self.totalMoneySpent
      }
      API.unLockTotalExp(data)
        .then((res) => {
          if (res.code > 0) {
            // Message.success(res.msg)
            self.allunlockDialogVisible = false
            this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })

          }
          else {
            // return Message.error(res.msg)
            return this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 延时时间
              displayTime: 1500
            })
          }
        })
    },
    // ==============================================================================
    // 右侧虚拟方法
    exportVirtualData() {
      var self = this;
      var url =
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/group/exportVirtualData?grpid=" +
        self.group_id;
      window.open(url);
    },

    drawEchartsPie1() 
    {
      var self = this;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("pie1"));
      // 绘制图表
      myChart.setOption({
        title: {
          subtext: "通关比关卡",
          left: "center",
        },
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          bottom: 10,
          left: 'center',
          data: ["通关", "剩余"]
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '50%'],
            // avoidLabelOverlap: false,
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '40',
            //     fontWeight: 'bold'
            //   }
            // },
            // labelLine: {
            //   show: false
            // },
            data: [
              {
                value: parseFloat(self.virtualData.bz),
                name: "通关",
                itemStyle: { color: '#3E95B5' }
              },
              {
                value: 100 - parseFloat(self.virtualData.bz),
                name: "剩余",
                itemStyle: { color: '#F5BD5C' }
              },
            ],
            label: {
              //去除饼图的指示折线label
              normal: {
                show: false,
                position: "inside",
                formatter: "{b}:{d}%",
              },
            },
          }
        ],

        // title: {
        //   subtext: "通关比关卡",
        //   left: "center",
        // },
        // tooltip: {
        //   trigger: "item",
        //   formatter: "{a} <br/>{b} : {c} ({d}%)",
        // },
        // legend: {
        //   bottom: 10,
        //   left: "center",
        //   data: ["剩余", "通关"],
        // },
        // series: [
        //   {
        //     name: "",
        //     type: "pie",
        //     radius: "50%",
        //     center: ["50%", "50%"],
        //     avoidLabelOverlap: false,
        //     data: [
        //       {
        //         value: parseInt(self.virtualData.tgsj),
        //         name: "通关",
        //       },
        //       {
        //         value: self.virtualData.gksj - self.virtualData.tgsj,
        //         name: "剩余",
        //       },
        //     ],
        //     label: {
        //       //去除饼图的指示折线label
        //       normal: {
        //         show: false,
        //         position: "inside",
        //         formatter: "{b}:{d}%",
        //       },
        //     },
        //   },
        // ],
      });
      // console.log(parseInt(self.virtualData.bz));
    },

    drawEchartsPie2() 
    {
      var self = this;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("pie2"));
      // 绘制图表
      myChart.setOption({
        title: {
          subtext: "完成人数统计",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          bottom: 10,
          left: "center",
          data: ["未完成", "完成"],
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ['40%', '50%'],
            // center: ["50%", "50%"],
            // avoidLabelOverlap: false,
            data: [
              {
                value: self.virtualData.wcr,
                name: "完成",
                itemStyle: { color: '#3E95B5' }
              },
              {
                value: self.virtualData.qzrs - self.virtualData.wcr,
                name: "未完成",
                itemStyle: { color: '#F5BD5C' }
              },
            ],
            label: {
              //去除饼图的指示折线label
              normal: {
                show: false,
                position: "inside",
                formatter: "{b}:{d}%",
              },
            },
          },
        ],
      });
    },

    drawEchartsBar1() 
    {
      var self = this;
      var arr1 = [];
      var arr2 = [];
      for (var i = 0; i < self.virtualData.list.length; i++) {
        arr1.push("关卡" + (i + 1));
        arr2.push(parseFloat((self.virtualData.list[i].pjsj) / 10 * 60));
      }
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("bar1"));
      myChart.setOption({
        title: { text: "平均时间/min" },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: arr1,
        },
        yAxis: { max: function (value) { return Math.floor(value.max) + 1; }, min: 0 },
        series: [
          {
            barWidth: 16,
            data: arr2,
            type: "bar",
            //颜色
            itemStyle: {
              normal: {
                color: "#3E95B5",
              },
            },
          },
        ],
      });
    },

    courseExpCensus() 
    {
      const self = this;
      if (self.virtualDate?.length <= 0) 
      {
        self.$Tips({
          // 消息提示内容
          message: '请选择日期',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        return;
      }
      if (!self.virtualExpId) {
        // Message.warning("请选择虚拟实验！");
        self.$Tips({
          // 消息提示内容
          message: '请选择虚拟实验',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        return;
      }
      let data = {
        stime: self.virtualDate[0],
        etime: self.virtualDate[1],
        exp_id: self.virtualExpId,
        grp_id: self.group_id,
      };

      localStorage.setItem("stime", self.virtualDate[0]);
      localStorage.setItem("etime", self.virtualDate[1]);

      // 开启loading
      nprogressStart();
      self.$Loading.show();
      // 请求数据
      API.CourseExpCensus(data)
        .then((result) => {
          if (result.code > 0) {
            self.virtualDataShow = true;
            self.virtualData = result.data;
            self.drawEchartsPie1();
            self.drawEchartsPie2();
            self.drawEchartsBar1();
          }
          this.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'success',
            // 延时时间
            displayTime: 1500
          })
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          nprogressClose();
          self.$Loading.hide();
        });
    },

    // ==============================================================================
    // 右侧证书列表方法
    exportStudentCertList() 
    {
      var self = this;
      var url =
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/group/exportStudentCertExcel?grpid=" +
        self.group_id +
        "&game_id=" +
        self.game_id +
        "&game_type_id=" +
        self.game_type_id;
      // +
      // "&activity_cid=" +
      // self.game_active_id;
      // console.log(url);
      window.open(url);
    },

    certPageChange(val) 
    {
      const self = this;
      self.certPage = val;
      self.getCertReckonList();
    },

    searchCertReckonList() 
    {
      this.certPage = 1;
      this.getCertReckonList();
    },

    getCertReckonList() 
    {
      /*const self = this;
      sleep(1000).then(() => {
        self.updateCssStyle(self.group_id);
      });
      let data = {
        group_id: self.group_id,
        course_id: self.cert_course_id,
        page: self.certPage,
        pagesize: self.certPageSize,
        search: self.certSearch
      };
      // 请求数据
      self.$router.push({
        path: "/DY/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      if (!self.isShow) {
        return;
      }
      else {
        nprogressStart();
        self.$Loading.show();
        API.getCertReckonList(data)
          .then((result) => {
            if (result.code > 0) {
              self.certTableData = result.group;
              self.certTotal = result.total;
              self.certInfo = result.certInfo;
              self.gamelist = result.gamelist;
              self.game_id = result.gamelist[0].id;
              self.certNum = result.certNum;
            }
            this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            nprogressClose();
            self.$Loading.hide();
          });
      }//*/

    },

    getGameTypeList() 
    {
      const self = this;
      let data = {
        game_id: self.game_id,
      };
      // 请求数据
      API.getGameTypeList(data)
        .then((result) => {
          self.game_type_id = "";
          if (result.code > 0) {
            self.gameTypelist = result.gameTypelist;
          }
        })
        .catch((err) => {
        });
    },
    // ==============================================================================
    // 右侧游戏列表方法
    gamePageChange(val) {
      const self = this;
      self.gamePage = val;
      self.getGameList();
    },

    getGameList() {
      /*const self = this;
      sleep(1000).then(() => {
        self.updateCssStyle(self.group_id);
      });
      let data = {
        group_id: self.group_id,
        page: self.gamePage,
        pagesize: self.gamePageSize,
      };

      self.$router.push({
        path: "/DY/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      if (!self.isShow) {
        return;
      }
      else {
        // 开启loading
        nprogressStart();
        self.$Loading.show();
        // 请求数据
        API.getGameList(data)
          .then((result) => {
            if (result.code > 0) {
              self.gameTableData = result.group;
              self.gameTotal = result.total;
              this.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      }//*/

    },
    // ==============================================================================
    // 右侧成绩列表方法
    scorePageChange(val) {
      const self = this;
      self.scorePage = val;
      // 全选选项默认不选择
      self.checkAll = false;
      self.getReleaseScore();
    },

    searchStudent() 
    {
      this.scorePage = 1;
      this.getReleaseScore();
    },

    getReleaseScore() 
    {
      /*const self = this;
      sleep(1000).then(() => {
        self.updateCssStyle(self.group_id);
      });
      let data = {
        group_id: self.group_id,
        page: self.scorePage,
        pagesize: self.scorePageSize,
        name: self.studentGroupSearch,
        lookType: self.lookType,
      };
      self.$router.push({
        path: "/DY/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      if (!self.isShow) {
        return;
      }
      else {
        nprogressStart();
        self.$Loading.show();
        // 请求数据
        API.showUNreleased(data)
          .then((result) => {
            self.$Loading.hide();
            self.scoreTableTitleList = result.proarr;
            self.scoreTableTitleList?.forEach((item) => {
              // 解析字符串中的权重
              item.weight = (parseFloat(item.name.substring(item.name.indexOf("*") + 1))  * 100).toFixed(1);
              // 解析字符串中的学时
              item.hours = item.name.substring(item.name.indexOf("*") + 1).substring(item.name.substring(item.name.indexOf("*") + 1).indexOf("("));
              // 作为前端循环的key，需要不一样数据才能更新
              item.keys = item.id + Math.random();
              // if (item.name.substring(item.name.indexOf("*") + 1).substring(0, item.name.substring(item.name.indexOf("*") + 1).indexOf("(")) > 1) 
              // {
              //   item.checked = true
              // }
              // else 
              // {
              //   item.checked = false
              // }
            })
            self.scoreTableData = result.releasearr;
            self.studentListTableData = result.releasearr;
            self.scoreTotal = result.total;
            nprogressClose();
            self.$Loading.hide();
            this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })
          })
          .catch((err) => {
            nprogressClose();
            self.$Loading.hide();
          });
      }//*/

    },

    // ==============================================================================
    // 右侧学生列表方法
    markHandleAvatarSuccess(res) 
    {
      const self = this;
      if (res.code > 0) {
        self.groupFilePath = res.path;
        // Message.success(res.msg);
        this.$Tips({
          // 消息提示内容
          message: res.msg,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'success',
          // 延时时间
          displayTime: 1500
        })
      } else {
        this.$Tips({
          // 消息提示内容
          message: res.msg,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 延时时间
          displayTime: 1500
        })
      }
    },
    exportStudentListScore() 
    {
      this.scoreDialogVisible = true;
      this.rawScoreSign = false;
      this.calcScoreSign = false;
      this.openTimeSign = false;
    },
    searchContent() 
    {
      this.getGroupInfo(this.group_id);
    },
    setAutoBrushPoint() {
      const self = this;
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      var data = {
        student_group_id: self.group_id,
      }
      // 解锁全部试验
      API.createExpRecord(data)
        .then((result) => {
          if (result.code > 0) {
            self.rightStudentDialogVisible = false;
            self.getGroupInfo(self.group_id);
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        });
    },
    removeAutoBrushPoint() {
      const self = this;
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      // 解锁全部试验
      API.delStudentBrush(data)
        .then((result) => {
          if (result.code > 0) {
            self.rightStudentDialogVisible = false;
            self.getGroupInfo(self.group_id);
            this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        });
    },
    handleSelectionChange(val) {
      let self = this;
      self.multipleSelection = val
      self.checkboxArr = self.multipleSelection.map((item) => { return item.id })
    },
    checkboxChange(e, param) 
    {
      const self = this;
      if (e) {
        self.checkboxArr.push(param);
      } else {
        let index = self.checkboxArr.indexOf(param);
        self.checkboxArr.splice(index, 1);
      }
      // ============================================================
      // 判断全选展示
      for (let i = 0; i < self.studentListTableData.length; i++) {
        if (self.studentListTableData[i].checkModel !== e) {
          self.checkAll = false;
          return;
        }
      }
      if (self.checkboxArr.length != 0) {
        self.checkAll = true;
      }
    },
    handleCheckAllChange(sign) 
    {
      const self = this;
      if (self.studentListTableData.length <= 0) {
        return;
      }
      for (let i = 0; i < self.studentListTableData.length; i++) {
        self.studentListTableData[i].checkModel = sign;
        self.checkboxArr.push(self.studentListTableData[i].id);
      }
      if (!sign) {
        self.checkboxArr = [];
      }
    },
    getGroupInfo(id, courseid, name = "", sign = "", type = true) {
      // 请求数据
      const self = this;

      if (!type) {
        self.isShow = false;
        sleep(50).then(() => {
          self.updateCssStyle(id);
        });
        self.group_id = id;
        self.cert_course_id = courseid;
        self.rightContentShow = false;
        self.expList = [];
        // 筛选日期数组['开始时间','结束时间']
        self.virtualDate = [];
        // 虚拟实验id
        self.virtualExpId = "";
        // 虚拟数据展示
        self.virtualDataShow = false;
        if (sign == "delete") {
          self.groupName = name;
          self.rightContentShow = false;
        }
        let data = {
          id: self.group_id,
          page: self.studentListPage,
          pagesize: self.studentListPageSize,
          search: self.studentListSearch,
        };
        // 开启loading
        self.activeName = 'first';
        self.$router.push({
          path: "/DY/xueqing",
          query: {
            group_id: self.group_id,
            activeName: self.activeName,
          },
        });
        return;
      }
      else {
        sleep(50).then(() => {
          self.updateCssStyle(id);
        });
        self.group_id = id;
        self.cert_course_id = courseid;
        self.rightContentShow = true;
        self.expList = [];
        // 筛选日期数组['开始时间','结束时间']
        self.virtualDate = [];
        // 虚拟实验id
        self.virtualExpId = "";
        // 虚拟数据展示
        self.virtualDataShow = false;
        if (sign == "delete") {
          self.groupName = name;
          self.rightContentShow = false;
        }
        let data = {
          id: self.group_id,
          page: self.studentListPage,
          pagesize: self.studentListPageSize,
          search: self.studentListSearch,
        };
        // 开启loading
        self.activeName = 'first';
        self.$router.push({
          path: "/DY/xueqing",
          query: {
            group_id: self.group_id,
            activeName: self.activeName,
          },
        });
        nprogressStart();
        self.$Loading.show();
        API.getGroupStudentInfo(data)
          .then((result) => {
            if (result.code > 0) {
              self.classCourseList = result.classCourseList
              self.studentListTableData = result.group;
              self.studentListTotal = result.total;
              self.activeName = "first";
              self.student_group_name = result.student_group_name;
              self.studentNum = result.studentNum;
              self.studentRegNum = result.studentRegNum;
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      }

    },
    // ====================================================================================
    // 左侧项目列表 方法
    /**
     * @name: 更改css样式
     * @author: camellia
     * @date: 2021-03-03
     * @param:	id	type	description
     */
    updateCssStyle(id) {
      // css效果
      var d = document.querySelectorAll(".left-module tr");
      for (var p = d.length; p--;) 
      {
        if (d[p].id != id) 
        {
          d[p].style.backgroundColor = "#FFFFFF"; /*其他班级*/
          d[p].classList.remove("selectTr");
        } 
        else 
        {
          d[p].style.backgroundColor = "#E5ECF1"; /*点击的班级*/
          d[p].classList.add("selectTr");
        }
      }
    },

    studentListPageChange(val) {
      const self = this;
      self.studentListPage = val;
      // 全选选项默认不选择
      self.checkAll = false;
      self.getGroupInfo(self.group_id);
    },

    getVirtualDataList() 
    {
      let self = this;
      sleep(50).then(() => {
        self.updateCssStyle(self.group_id);
      });
      let data = {
        group_id: self.group_id,
        search: self.virtualSearch
      };
      self.$router.push({
        path: "/DY/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      nprogressStart();
      self.$Loading.show();
      API.getVirtualDataList(data)
        .then((res) => {
          if (res.code > 0) 
          {
            self.expprojectList = res.projectList
            self.balance = res.balance
            self.lockNum = res.lockNum
            self.totalMoneySpent = res.totalMoneySpent
          }
          nprogressClose();
          self.$Loading.hide();
        })
        .catch(() => {
          nprogressClose();
          self.$Loading.hide();
        })//*/
    },

    getExpList() 
    {
      const self = this;
      let data = {
        group_id: self.group_id,
      };
      self.$router.push({
        path: "/DY/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
          exp_id: self.virtualExpId,
        },
      });
      console.log(data)
      if (!self.isShow) 
      {
        return;
      }
      else 
      {
        // 开启loading
        nprogressStart();
        self.$Loading.show();
        self.axios.post('/index.php/backStatistics/getExpList', data).then(result => {
          if (result.data.code > 0) 
          {
            self.virtualDate = [];
            self.virtualDate.push(localStorage.getItem("stime") ?? '');
            self.virtualDate.push(localStorage.getItem("etime") ?? '');
            self.expList = result.data.data ? result.data.data : [];
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        });
      }
    },

    leftHandleOpen(sign, title, id = "", name = "", courseid = "", groupType = "") 
    {
      const self = this;
      self.leftDialogVisible = true;
      self.leftDialogSign = sign;
      self.leftDialogTitle = title;
      self.left_group_id = id ? id : self.left_group_id;
      self.groupName = name ? name : self.groupName;
      self.groupType = groupType ? groupType : self.groupType;
      self.course_id = courseid ? courseid : self.course_id;
    },

    showAllSceen() 
    {
      let group = this.groupList.find((item) => {
        return item.id == this.$router.currentRoute.query.group_id
      })
      window.open("#/teacher/unpublishGrade?hideMenu=1&name=" + group.name + '&group_id=' + group.id)
    },
    showExpStatDialog(index=0,page=1)
    {
      const self = this;
      if(index)
      {
        self.echartsDialogIndex = index;
      }
      self.statPage = page;
      nprogressStart();
      self.$Loading.show();
      let data = {
        exp_id: self.virtualExpId,
        CID: self.echartsDialogIndex,
        statPage:self.statPage
      };
      API.getExpCidStat(data)
        .then((result) => {
          if(result.code > 0)
          {
            self.echartsDialogTotal = parseInt(result.total);
            self.echartsDialogVisible = true;
            self.showExpCIDEcharts(result.expStepShow,result.errorRateArray,result.avgTimeArray,index);
          }
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          nprogressClose();
          self.$Loading.hide();
        });
    },

    changeEchartsPage(e)
    {
      this.showExpStatDialog(this.echartsDialogIndex,e);
    },

    showExpCIDEcharts(expStepShow,errorRateArray,avgTimeArray,index)
    {
      this.$nextTick(() => {
        var chartDom = document.getElementById('mains');
        var myChart = echarts.init(chartDom);
        var option;
        option = {
          title: {
            text: '关卡'+index
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: expStepShow
          },
          series: [
            {
              name: '平均时间/秒',
              type: 'bar',
              data: avgTimeArray,
              color: ['#3E95B5'],
            },
            {
              name: '错误率%',
              type: 'bar',
              data: errorRateArray,
              color: ['#BA120A'],
            }
          ]
        };
        option && myChart.setOption(option);   
      })         
    },

    // ===================================================================
    /**
     * @name: 展示学生成绩总分分布统计图
     * @author: wyf
     * @data: 2023-05-31
     * @param: group_id 班级id
     */
    shouScoreDialog (group_id) 
    {
      const self = this;
      let group = this.groupList.find((item) => {
        return item.id == this.$router.currentRoute.query.group_id
      })
      // 保存当前班级的名字
      self.groupName = group.name
      // 获取当前班级的成绩
      API.getStatData({group_id:group_id})
      .then(res=>{
        console.log(res);
        self.studentScore = res
        // 渲染echarts图表
        self.$nextTick(()=>{
          // 渲染echarts图表
          self.studentScoreEcharts = echarts.init(document.getElementById('studentScore'));
          let option ={
            title: {
              text: '总分分布统计图',
              left:'center'
            },
            xAxis: {
              data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
              name:'分数段(%)'
            },
            yAxis: {
              type: 'value',
              name:'人数',

            },
            legend: {
              bottom: '5%'
            },
            series: [
              {
                name:'人数',
                emphasis: {
                  focus: 'series'
                },
                zlevel: 2,
                data: [res.ten_nine_number,res.nine_eight_number,res.eight_seven_number,res.seven_six_number,res.zero_six_number],
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                  color: 'rgba(180, 180, 180, 0.2)'
                },
                label: {
                  show: true,
                  position: 'top'
                },
                itemStyle: {
                  normal: {
                    //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                    barBorderRadius: [10, 10, 10, 10],
                    color:'#3E95B5',
                    opacity:.9,
                  },
                }, 
                barWidth: 20,
              },
              {
                name:"曲线",
                emphasis: {
                  focus: 'series'
                },
                zlevel: 1,
                data: res.code === -1 ? ['0','0','0','0','0'] : [ res.ten_nine_number, res.nine_eight_number, res.eight_seven_number, res.seven_six_number, res.zero_six_number],
                type:'line',
                smooth: true,
                lineStyle:{
                  color:'#0D5570'
                },
                showSymbol: false,//去除圆点
                areaStyle: {},
                areaStyle: {
                    color: '#C4DFE9'
                  }
              }
            ],
          }
          option && self.studentScoreEcharts.setOption(option);
          // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
          window.addEventListener("resize", () => {
            // 执行echarts自带的resize方法，让echarts图表自适应
            self.studentScoreEcharts.resize();
          })
      })   
      })
      // 打开弹出框
      self.studentScoreDialogVisible=true;
      // 获取当前班级总人数
      API.getGroupStudentInfo({id:group_id}).then(res=>{
        self.studentNum=res.studentNum
      })
    },
  },
  // 组件销毁之前的生命周期函数
  beforeDestroy() {
    /**
     * @name: 页面组件销毁的时候,移除绑定的监听resize事件
     * @author: wyf
     * @data: 2023-06-01
     */
    window.removeEventListener("resize", () => {
      this.studentScoreEcharts.resize();
    });
  },
};
