<template>
  <!-- 素材列表页 -->
  <div class="pdf">
    <!-- 左侧模块 -->
    <div class="left-module">
      <div class="addnewProject">
        <!-- 班级列表 -->
        <div>
          <div class="projectListBox">
            <!-- 拖拽排序时显示 -->
            <table class="sortableTable" >
              <tr :id="item.id" v-for="(item, index) in groupList" :key="index">
                <!-- 班级名称 -->
                <td class="td-project" :style="item.type == 1 ? 'color:red' : ''"
                  @click="getcurrentActive(item.id)">
                  <i class="el-icon-success green" v-if="item.isopen"></i>
                  {{ item.name }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧模块 -->
    <div class="div border0">
      <!-- 标签页 -->
      <div v-if="rightContentShow" class="setDetail">
        <div v-if="expList.length > 0" class="virtual">
          <div class="expSearch">
            <el-input clearable placeholder="请输入项目名称" v-model="virtualSearch" @clear="getVirtualDataList">
              <el-button slot="append" class="el-icon-search" @click="getVirtualDataList"></el-button>
            </el-input>
          </div>
          <div>
            <el-date-picker v-model="virtualDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <el-select v-model="virtualExpId" placeholder="请选择" clearable class="expSelect">
              <el-option v-for="item in expList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <!-- 不点统计无法导出 -->
            <el-button class="themecancelButton" @click="courseExpCensus()">统计</el-button>
            <!-- <div class="themeText floatRight" v-if="virtualDataShow" @click="exportVirtualData()">导出Excel</div> -->
          </div>
          <!-- 数据显示 + echarts -->
          <div class="data clearFix">
            <div class="data clearFix" :class="virtualDataShow ? '' : 'hiddenEcharts'">
              <!-- 数据显示 -->
              <div class="data-show">
                <div class="width100 item-data">
                  <div class="left width50">关卡数</div>
                  <div class="float-right width50">{{ virtualData.gks }}</div>
                </div>
                <div class="width100 item-data">
                  <div class="left width50">班级人数</div>
                  <div class="float-right width50">{{ virtualData.qzrs }}</div>
                </div>
                <div class="width100 item-data">
                  <div class="left width50">完成人数</div>
                  <div class="float-right width50">{{ virtualData.wcr }}</div>
                </div>
                <div class="width100 item-data">
                  <div class="left width50">完成度</div>
                  <div class="float-right width50">{{ virtualData.wcd }}</div>
                </div>
                <div class="width100 item-data">
                  <div class="left width50">数据总数</div>
                  <div class="float-right width50">{{ virtualData.sj }}</div>
                </div>
                <div class="width100 item-data">
                  <div class="left width50">通关时间</div>
                  <div class="float-right width50">{{ virtualData.tgsj }}</div>
                </div>
                <div class="width100 item-data">
                  <div class="left width50">关卡时间</div>
                  <div class="float-right width50">{{ virtualData.gksj }}</div>
                </div>
                <div class="width100 item-data">
                  <div class="left width50">通关时间/关卡时间</div>
                  <div class="float-right width50">{{ virtualData.bz }}</div>
                </div>
                <!-- 关卡数此处循环 -->
                <div class="width100 item-data" v-for="(item, index) in virtualData.list" :key="index">
                  <div class="left width50" @click="showExpStatDialog(index + 1)" style="cursor:pointer">
                  关卡 {{ index + 1 }}
                    <img src="../../assets/img/箭头3x.png" style="width: 15px;">
                  </div>
                  <div class="float-right width50">
                    平均时间:{{ item.pjsj }}
                    <div class="left width30px"></div>
                    重复度:{{ item.cfd }}
                  </div>
                </div>

                <div class="width100 item-data">
                  <div class="left width50">总计</div>
                  <div class="float-right width50">{{ virtualData.zsj }}</div>
                </div>
              </div>
              <!-- echarts -->
              <div class="data-echarts">
                <!-- 两个饼图 -->
                <div>
                  <!-- 左侧饼图 -->
                  <div class="left pie" id="pie1"></div>
                  <!-- 右侧饼图 -->
                  <div class="left pie" id="pie2"></div>
                </div>
                <!-- 一个柱形图 -->
                <div id="bar1" class="bar"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h3>未绑定虚拟项目</h3>
          <br />
        </div>
      </div>
      <div v-else>
        <div class="recover-button">
          <h3>{{ groupName }}</h3>
          <br />
          <el-button class="themeButton" @click="leftHandleOpen('recover', '恢复项目')">恢复</el-button>
        </div>
      </div>
    </div>

    <div class="stasticDialogVisible">
      <el-dialog title="统计" :visible.sync="stasticDialogVisible" width="70%">
        <!-- 时间选择框 + 按钮 -->
        <div class="selectVir">
          <el-date-picker v-model="virtualDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          <el-select v-model="virtualExpId" placeholder="请选择" clearable class="expSelect">
            <el-option v-for="item in expList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <!-- 不点统计无法导出 -->
          <el-button class="themeButton" @click="courseExpCensus()">统计</el-button>
          <!-- <div class="themeText floatRight" v-if="virtualDataShow" @click="exportVirtualData()">导出Excel</div> -->
        </div>
        <!-- 数据显示 + echarts -->
        <div class="data clearFix">
          <div class="data clearFix" :class="virtualDataShow ? '' : 'hiddenEcharts'">
            <!-- 数据显示 -->
            <div class="data-show">
              <div class="width100 item-data">
                <div class="left width50">关卡数</div>
                <div class="float-right width50">{{ virtualData.gks }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">班级人数</div>
                <div class="float-right width50">{{ virtualData.qzrs }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">完成人数</div>
                <div class="float-right width50">{{ virtualData.wcr }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">完成度</div>
                <div class="float-right width50">{{ virtualData.wcd }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">数据总数</div>
                <div class="float-right width50">{{ virtualData.sj }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">通关时间</div>
                <div class="float-right width50">{{ virtualData.tgsj }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">关卡时间</div>
                <div class="float-right width50">{{ virtualData.gksj }}</div>
              </div>
              <div class="width100 item-data">
                <div class="left width50">通关时间/关卡时间</div>
                <div class="float-right width50">{{ virtualData.bz }}</div>
              </div>
              <!-- 关卡数此处循环 -->
              <div class="width100 item-data" v-for="(item, index) in virtualData.list" :key="index">
                <div class="left width50">关卡 {{ index + 1 }}</div>
                <div class="float-right width50">
                  平均时间:{{ item.pjsj }}
                  <div class="left width30px"></div>
                  重复度:{{ item.cfd }}
                </div>
              </div> 
              <div class="width100 item-data">
                <div class="left width50">总计</div>
                <div class="float-right width50">{{ virtualData.zsj }}</div>
              </div>
            </div>
            <!-- echarts -->
            <div class="data-echarts">
              <!-- 两个饼图 -->
              <div>
                <!-- 左侧饼图 -->
                <div class="left pie" id="pie1"></div>
                <!-- 右侧饼图 -->
                <div class="left pie" id="pie2"></div>
              </div>
              <!-- 一个柱形图 -->
              <div id="bar1" class="bar"></div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <div class="allunlockDialog" v-show="allunlockDialogVisible">
      <!-- 添加/修改/删除/恢复项目弹窗 -->
      <el-dialog title="提示" :visible.sync="allunlockDialogVisible" width="30%">
        <span>
          <div class="unlockText">账户余额：{{ balance }} 积分</div>
          <div class="unlockText">实验 {{ lockNum }} 人购买需：{{ totalMoneySpent }} 积分</div>
          <div class="unlockText">是否购买？</div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button class="unlockcancelButton" @click="allunlockDialogVisible = false">取消</el-button>
          <el-button class="unnlockaddButton" @click="allUnlock">确定</el-button>
        </span>
      </el-dialog>
    </div>
    
    <!-- 学情分析 echarts 弹窗 -->
    <el-dialog class="echartsDialog" :title="echartsDialogTitle" :visible.sync="echartsDialogVisible" :width="echartsDialogWidth" center>
      <div class="groupCode" id="mains" ></div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="echartsDialogTotal"
          :page-size="5"
          :current-page="statPage"
          @current-change="changeEchartsPage">
        </el-pagination>
      </div>
    </el-dialog>

    <!-- add buy wyf 2023-07-28 -->
    <!-- 学生成绩总分分布统计图 -->
    <el-dialog class="studentScoreDialog"  :visible.sync="studentScoreDialogVisible" width="50%" center>
      <!-- 当前班级名称 -->
    <div >
    {{ groupName }}
    </div>
    <!-- 表格 -->
    <el-row class="main" >
      <el-col style="font-size: 0.938vw; text-align: center;">总分分布</el-col>
      <el-col :span="12">
        <!-- <div class="grid-content bg-purple"></div> -->

        <el-row class="score" >
          <el-col class="td" :span="4">分数段</el-col>
          <el-col class="td" :span="4">[90,100]</el-col>
          <el-col class="td" :span="4">[80,90)</el-col>
          <el-col class="td" :span="4">[70,80)</el-col>
          <el-col class="td" :span="4">[60,70)</el-col>
          <el-col class="td" :span="4">[0,60)</el-col>
        </el-row>

        <el-row class="score" >
          <el-col class="td" :span="4">人数</el-col>
          <el-col class="td" :span="4">{{ studentScore.ten_nine_number ? studentScore.ten_nine_number :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.nine_eight_number ? studentScore.nine_eight_number :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.eight_seven_number ? studentScore.eight_seven_number :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.seven_six_number ? studentScore.seven_six_number :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.zero_six_number ? studentScore.zero_six_number :0 }}</el-col>
        </el-row>

        <el-row class="score" >
          <el-col class="td" :span="4">百分比</el-col>
          <el-col class="td" :span="4">{{ studentScore.ten_nine_percent ? studentScore.ten_nine_percent :0 }}%</el-col>
          <el-col class="td" :span="4">{{ studentScore.nine_eight_percent ? studentScore.nine_eight_percent :0 }}%</el-col>
          <el-col class="td" :span="4">{{ studentScore.eight_seven_percent ? studentScore.eight_seven_percent :0 }}%</el-col>
          <el-col class="td" :span="4">{{ studentScore.seven_six_percent ? studentScore.seven_six_percent :0 }}%</el-col>
          <el-col class="td" :span="4">{{ studentScore.zero_six_percent ? studentScore.zero_six_percent :0 }}%</el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <!-- <div class="grid-content bg-purple-light"></div> -->
        <el-row class="score" style="border-right: 1px solid #ccc;" >
          <el-col class="td" :span="4">最高分</el-col>
          <el-col class="td" :span="4">最低分</el-col>
          <el-col class="td" :span="4">平均分</el-col>
          <el-col class="td" :span="4">及格率</el-col>
          <el-col class="td" style="border-right: 0;" :span="4">相对偏差</el-col>
        </el-row>
        <el-row class="score" style="border-right: 1px solid #ccc;">
          <el-col class="td" :span="4">{{ studentScore.maxScore ? studentScore.maxScore :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.minScore ? studentScore.minScore :0 }}</el-col>
          <el-col class="td" :span="4">{{ studentScore.avgScore ? studentScore.avgScore :0 }}</el-col>
          <el-col class="td" :span="4">{{ (studentNum - studentScore.zero_six_number) / studentNum ? (studentNum - studentScore.zero_six_number) / studentNum : 0 }}%</el-col>
          <el-col class="td" style="border-right: 0;" :span="4">{{ studentScore.relativeDev ? studentScore.relativeDev :0 }}%</el-col>
        </el-row>
        <el-row class="score" style="border-right: 1px solid #ccc;">
          <el-col class="td" :span="4"></el-col>
          <el-col class="td" :span="4"></el-col>
          <el-col class="td" :span="4"></el-col>
          <el-col class="td" :span="4"></el-col>
          <el-col class="td" style="border-right: 0;" :span="4"></el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 图表 -->
    <div id="studentScore"></div>

    </el-dialog>

  </div>
</template>
<style lang="scss" >
@import "@/assets/css/teacher/group/groupListnoScoped.scss";
.groupCode{
  width:100%;height:500px;
}
.pdf .expSearch
{
    position: fixed;
    top: 5.6vw !important;
    right: 2.5vw;
}

.el-radio__input.is-checked+.el-radio__label {
  color: #0D5570 !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #0D5570 !important;
  background: #0D5570 !important;
}

.el-dialog__body {
  padding: 30px 20px;
  padding-top: 5px !important;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  padding-bottom: 30px;
  text-align: center;
}

.pdf .is-active {
  border-left: 0 !important;
}

.weight .el-table .el-table__cell {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/teacher/group/groupList.scss";
</style>

<script>
  import Calendar from "@/assets/js/DY/xueqing.js";
  export default {
    ...Calendar
  };
</script>
